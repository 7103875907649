<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <div class="w-full">
      <el-select
        v-model="localValue"
        v-cancel-read-only="filter.filterable || false"
        class="w-full"
        clearable
        autocorrect="off"
        autocapitalize="off"
        autocomplete="nope"
        spellcheck="false"
        popper-append-to-body
        :filterable="filter.filterable"
        :disabled="filter.disabled || false"
        :placeholder="filter.placeholder"
      >
        <el-option
          v-for="option in filter.options"
          :key="option.key || option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
